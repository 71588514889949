import { jsx } from "react/jsx-runtime";
import { lazy, useMemo } from "react";
const LazyImage = lazy(
  () => import("./image-Dx3dO21m.js").then((module) => ({
    default: module.Image
  }))
);
function Image({
  id: _id,
  name: _name,
  ...props
}) {
  return /* @__PURE__ */ jsx(LazyImage, { ...props });
}
const useImageContent = () => {
  return useMemo(
    () => ({
      image: {
        component: Image
      }
    }),
    []
  );
};
export {
  LazyImage as Image,
  useImageContent
};
